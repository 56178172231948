<template>
    <div
        class="eq-estimate__results"
        :class="{'eq-estimate__results--loading': queryInProgress }"
    >
        
        <!-- Loading state -->
        <div v-if="queryInProgress">
            <ClockIcon class="eq-estimate__results-loading-icon"/>
            <h4>{{ $t('initial_estimate.results.loading.title') }}</h4>
            <div class="mb-5">{{ $t('initial_estimate.results.loading.desc') }}</div>
            <div class="eq-estimate__results-loading-bar">
                <div class="eq-estimate__results-loading-bar-inner"></div>
            </div>
        </div>
        
        <template v-else>
            <!-- Estimate results -->
            <div v-if="!hasSubmitError">
                <img
                    :src="imagePath"
                    class="eq-estimate__results-img"
                >
                <h3 class="eq-estimate__results-title">
                    {{ $t('initial_estimate.results.title') }}
                </h3>
                <i18n-t keypath="initial_estimate.results.desc" tag="p" scope="global" class="mb-6">
                    <template v-slot:ventureName>
                        {{ ventureName }}
                    </template>
                    <template v-slot:valuationRange>
                        <span class="eq-estimate__results-range">{{ lowBoundValuation }} - {{ highBoundValuation }}</span>
                    </template>
                </i18n-t>
                <button
                    class="eq-estimate__results-cta vc4a-btn vc4a-btn--primary"
                    role="button"
                    @click="$emit('close:eq-modal');"
                >
                    {{ $t('initial_estimate.results.cta') }}
                </button>
            </div>
            
            <!-- Error -->
            <div v-else>
                <div class="eq-estimate__results-error-icon-wrapper">
                    <ExclamationTriangleIcon
                        class="eq-estimate__results-error-icon"
                    />
                </div>
                <h3>{{ $t('initial_estimate.results.error.title') }}</h3>
                <div class="mb-6">
                    {{ $t('initial_estimate.results.error.desc') }}
                </div>
                <button
                    class="eq-estimate__results-cta vc4a-btn vc4a-btn--primary"
                    role="button"
                    @click="$emit('update:return-to-form');"
                >
                    {{ $t('initial_estimate.results.error.cta') }}
                </button>
            </div>
        </template>
        
        
    </div>
</template>

<script>
import { VC4A_VARS } from '@/shared/utlis';
import { ClockIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline';

export default {
    name: 'InitialEstimateResults',
    props: {
        ventureName: String,
        lowBoundValuation: [String, Number],
        highBoundValuation: [String, Number],
        hasSubmitError: Boolean,
        queryInProgress: Boolean,
    },
    components: {
        ClockIcon,
        ExclamationTriangleIcon
    },
    emits: ['close:eq-modal', 'update:return-to-form'],
    data() {
        return {
            imagePath: `${VC4A_VARS.VC4A_SITE_ASSETS_PATH}images/equidam/dollar-dynamic-color.png`
        }
    }
}
</script>
