export default {
  /* eslint  quotes: ["warn", "double"], indent: ["warn", 2] */
  "translation": {
    "common": {
      "success": {
        "changes": "Sauvegardé avec succès"
      },
      "errors": {
        "basic": "Quelque chose s'est mal passé. Veuillez réessayer."
      },
      "tags": {
        "is_optional": "optionnel",
      },
      "ctas": {
        "cancel": "Annuler",
        "save_changes": "Sauvegarder",
      },
      "options": {
        "yes": "Oui",
        "no": "Non",
      },
    },
    "email": {
      "sender": "Courriel Expéditeur",
      "subject": "Sujet du courriel",
      "body": "Corps de l'email",
      "cta": "Envoyer un e-mail",
      "cta_group": "Envoi d'un courriel groupé",
      "recipients_one": "Destinataire:",
      "recipients_other": "Destinataires:",
    },
    "cohorts": {
      "settings": {
        "cohort_status": {
          "active_state": {
            "title": "Active",
            "text": "Cette option active la visibilité de la cohorte. Les membres de la cohorte pourront consulter les rapports de la cohorte et s'y engager. Veillez à utiliser cette fonction lorsque la cohorte est prête à être partagée avec le groupe.",
          },
          "inactive_state": {
            "title": "Inactif",
            "text": "Cette option désactive la visibilité de la cohorte. Cela garantit que le contenu de la cohorte reste privé et n'est pas visible pour les membres de la cohorte. C'est l'option par défaut lorsqu'une cohorte vient d'être créée.",
          },
          "confirm_active": {
            "title": "Confirmer la visibilité de la cohorte",
            "body": "En confirmant, vous activez la visibilité de la cohorte. Les membres de la cohorte auront accès aux rapports de la cohorte et pourront les consulter. Cette action doit être effectuée lorsque la cohorte est prête à être partagée avec le groupe. Cela garantit que le contenu de la cohorte sera visible par tous les membres du profil de la venture. Êtes-vous sûr de vouloir continuer ?",
            "cta": "Activer la visibilité",
          },
          "confirm_inactive": {
            "title": "Confirmer la confidentialité de la cohorte",
            "body": "En confirmant, vous désactivez la visibilité de la cohorte. Cela garantit que le contenu de la cohorte reste privé et n'est pas visible par les membres de la cohorte. Cela signifie que les rapports de la cohorte ne seront plus accessibles aux membres du profil de l'entreprise. Êtes-vous sûr de vouloir désactiver la visibilité ?",
            "cta": "Désactiver la visibilité",
          },
        },
        "messaging": {
          "edit_email": "Modifier l'e-mail",
          "modal_title": "E-mailer",
          "reporting": {
            "title": "Rapport sur les courriels",
          },
          "reminder": {
            "title": "Activer les emails de rappel",
            "body": "Active l'envoi de courriels de rappel aux membres avant chaque date de déclaration.",
          },
        },
      },
      "remove_member_btn": "Retirer de la cohorte",
    },
    "venture_add": {
      "title_step_1": "Détails de l'aventure",
      "title_step_2": "Contact et public de l'entreprise",
      "title_step_3": "Présence en ligne",
      "customer_type_modal": {
        "title": "Plus d'informations pour le type de clients"
      },
      "step_counter": "Étape {{counter}} sur {{total}}",
      "form_controls": {
        "previous": "Retourner à",
        "next": "Continuer",
        "submit": "Enregistrer et visualiser le profil d'entreprise",
      },
      "field_titles" : {
        "company_name" : "Quel est le nom de votre entreprise ?",
        "choose_global": "Mondial",
        "pitch_video_url": "Par exemple, http://vimeo.com/28175752. Votre vidéo sera intégrée dans votre profil d'entreprise",
        "tagline": "Lema de la empresa",
      },
      "field_labels" : {
        "address_main": "Adresse complète",
        "city": "Ville",
        "company_name" : "Nom de l'entreprise",
        "country": "Pays",
        "customer_base": "Base de clients",
        "customer_type": "Type de clients",
        "focus_locations": "Pays",
        "founding_date": "Date de création",
        "phone_number": "Numéro de téléphone",
        "pitch_video_url": "URL de la vidéo de présentation",
        "pitch": "Argumentaire",
        "sectors": "Secteurs",
        "stage": "Stade",
        "street_address": "Adresse de la rue",
        "tagline": "Lema",
        "website_url": "Votre site Web",
      },
      "field_help_text": {
        "address_auto": "Remplir l'adresse et confirmer en sélectionnant l'adresse dans la liste.",
        "address_m_text": "Vous ne trouvez pas l'adresse ? Essayez un point de repère ou une ville proche ou",
        "address_m_link": "Saisissez l'adresse manuellement",
        "customer_type": "Plusieurs modèles de clients peuvent être sélectionnés",
        "customer_type_link": "Afficher plus de détails pour les modèles",
        "focus_locations": "Sélectionnez les pays que vous ciblez actuellement avec votre produit ou service.",
        "phone_number": "Veuillez inclure tout numéro de pays",
        "pitch_video_url": "Cela peut être l'URL de votre vidéo Instagram, YouTube, Vimeo, DailyMotion ou de votre présentation Slideshare.",
        "pitch": "Soyez aussi bref, concis et précis que possible. Dites-nous ce que vous faites et pourquoi votre entreprise a du potentiel.",
        "sectors": "Dans quel secteur d'activité votre entreprise est-elle la plus active ? Sélectionnez au maximum 3 secteurs.",
        "tagline": "Dites-nous en quelques mots ce qu'est votre entreprise.",
        "website_url": "Si vous n'avez pas de site web, veuillez fournir la page de médias d'information sociale de votre entreprise (comme Facebook ou LinkedIn).",
      },
      "field_placeholders": {
        "pitch_video_url": "Ajoutez votre lien YouTube ou Vimeo complet ici. Assurez-vous qu'il commence par http://"
      },
      "field_customer_base": {
        "titles": {
          "urban": "Clients basés en milieu urbain",
          "rural": "Clients ruraux",
        },
        "descriptions": {
          "urban": "Les clients se trouvent principalement dans les villes.",
          "rural": "Les clients se trouvent principalement dans les villages et les zones rurales.",
        }
      }
    },
    "validations" : {
      "required" : "Ce champ est obligatoire",
      "minValue" : "Ce champ doit être au moins {min}",
      "maxValue" : "Ce champ doit être au maximum {max}",
      "email" : "Veuillez saisir une adresse électronique valide",
      "minLength" : "Ce champ doit contenir au moins {min} caractères",
      "maxLength" : "Ce champ doit contenir au maximum {max} caractères",
      "integer" : "Cette valeur doit être un nombre entier",
    },
    "initial_estimate": {
      "heading" : {
        "title" : "Estimation initiale",
        "description" : "Commencez par créer une estimation de votre entreprise en vous basant sur ce que valent d'autres entreprises",
        "section_venture_basics" : "Les bases du capital-risque",
        "section_venture_stage" : "Le stade du capital-risque",
        "section_venture_operations" : "Opérations de capital-risque"
      },
      "form" : {
        "founder_number" : "Combien de fondateurs a {nom de l'entreprise} ?",
        "employee_number" : "Combien d'employés travaillent pour {nom de l'entreprise} ?",
        "country" : "Quel est le pays où {ventureName} est basée ?",
        "industry" : "Quel est le secteur d'activité de {ventureName} ?",
        "started_year" : "Quand l'entreprise a-t-elle été créée ?",
        "dev_stage" : "Quel est le stade de développement auquel vous êtes actuellement confronté ?",
        "ip_type" : "Quel type de propriété intellectuelle (PI) s'applique au produit/service ?",
        "profitability" : "{ventureName} atteint-elle durablement le seuil de rentabilité ?",
        "revenue_y1" : "Chiffre d'affaires de l'année écoulée ($)",
        "revenue_y4" : "Projections de revenus pour les 3 prochaines années ($)",
      },
      "dev_stage_options" : {
        "type1_title" : "Phase d'idées",
        "type1_desc" : "Travailler sur un plan d'affaires et tester les hypothèses initiales problème-solution",
        "type2_title" : "Phase de développement",
        "type2_desc" : "Construire le produit et mettre en place les opérations",
        "type3_title" : "Phase de démarrage",
        "type3_desc" : "Actif avec un revenu annuel allant jusqu'à 300 000 dollars",
        "type4_title" : "Stade d'expansion",
        "type4_desc" : "Opérations en expansion, avec un revenu annuel supérieur à 300 000 $",
        "type5_title" : "Stade de croissance",
        "type5_desc" : "Une entreprise de taille importante avec une croissance robuste, prouvée et reproductible",
        "type6_title" : "Stade de maturité",
        "type6_desc" : "Une entreprise rentable avec une croissance prévisible alignée sur l'industrie"
      },
      "ip_type_options" : {
        "type1_title" : "Marque et/ou noms de domaine",
        "type2_title" : "Droit d'auteur",
        "type3_title" : "Brevet (à l'exclusion du brevet provisoire américain)",
        "type4_title" : "Autres",
        "type5_title" : "Aucun ou non applicable",
      },
      "profitability_options" : {
        "type1_title" : "Oui",
        "type2_title" : "Non",
      },
      "controls":{
        "back" : "Retour",
        "continue" : "Continuer",
        "cta" : "Estimer ma valorisation"
      },
      "results" : {
        "title" : "Estimation initiale terminée",
        "desc" : "{ventureName} a été initialement estimée pour être évaluée entre {valuationRange}",
        "cta" : "Retour au profil de l'entreprise",
        "loading": {
          "title": "Les chiffres à la loupe...",
          "desc": "Nous sommes en train de générer votre devis initial. Cela ne prendra que quelques secondes !"
        },
        "error": {
          "title": "Oops ! Quelque chose s'est mal passé",
          "desc": "Une erreur s'est produite. Veuillez réessayer ou actualiser la page si le problème persiste.",
          "cta": "Veuillez réessayer"
        }
      }
    },
    "biz_valuation" : {
      "empty_state" : {
        "desc" : "Tirer parti d'une méthodologie de pointe et de données de marché pour calculer une évaluation préliminaire de votre entreprise",
        "cta" : "Obtenir une première estimation"
      },
      "title" : "Outil d'évaluation des entreprises",
      "high_bound_valuation": "Valorisation élevée",
      "low_bound_valuation": "Valorisation basse",
      "venture_estimate": "La valeur estimée de votre entreprise est comprise entre {range}. {cta}",
      "edit_estimate_cta": "Modifier l'estimation initiale",
      "goto_equidam_explainer": "Besoin d'un rapport d'évaluation complet ? {cta} et bénéficiez d'une remise de 10 %.",
      "goto_equidam_cta": "Procéder avec Equidam",
      "powered_by": "Développé par"
    }
  },
};
