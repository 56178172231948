<template>
    <teleport to="#business-valuation-card-target" :disabled="!showBusinessValuationCard" :key="componentKey">
        <template v-if="showBusinessValuationCard">
            <div
                class="biz-valuation-card--active card"
                v-if="showValuationResults"
            >
                <div class="biz-valuation-card__header">
                    {{ $t('biz_valuation.title') }}
                </div>
                <div class="biz-valuation-card__body">
                    <div class="biz-valuation-card__stats grid cols-2@lg">
                        <div class="biz-valuation-card__stats-item">
                            <div class="biz-valuation-card__stats-number">
                                {{ lowValuationWithCommas }}
                            </div>
                            <span class="biz-valuation-card__stats-desc">
                        {{ $t('biz_valuation.low_bound_valuation') }}
                    </span>
                        </div>
                        <div class="biz-valuation-card__stats-item">
                            <div class="biz-valuation-card__stats-number">
                                {{ highValuationWithCommas }}
                            </div>
                            <span class="biz-valuation-card__stats-desc">
                        {{ $t('biz_valuation.high_bound_valuation') }}
                    </span>
                        </div>
                    </div>
                    <i18n-t keypath="biz_valuation.venture_estimate" scope="global" tag="div">
                        <template v-slot:range>
                        <span>
                            {{ lowValuationCompact }} - {{ highValuationCompact }}
                        </span>
                        </template>
                        <template v-slot:cta>
                            <a
                                href="#"
                                @click.prevent="showEstimateForm = true"
                            >
                                {{ $t('biz_valuation.edit_estimate_cta') }}
                            </a>
                        </template>
                    </i18n-t>
                    <i18n-t keypath="biz_valuation.goto_equidam_explainer" tag="div" scope="global">
                        <template v-slot:cta>
                            <a
                                :href="referralLinkToEquidam"
                                target="_blank"
                            >
                                {{ $t('biz_valuation.goto_equidam_cta') }}
                            </a>
                        </template>
                    </i18n-t>
                </div>
            </div>
            <div
                v-else
                class="biz-valuation-card biz-valuation-card--empty"
            >
                <img :src="bizValuationIllustrationPath" class="biz-valuation-card__img" />
                <div class="m-3 m-0@lg">
                    <h3 class="biz-valuation-card__title">{{ $t('biz_valuation.title') }}</h3>
                    <p class="mb-5">{{ $t('biz_valuation.empty_state.desc') }}</p>
                    <div class="flex my-5 py-3">
                        <span class="mr-4 text--xs">{{ $t('biz_valuation.powered_by') }}</span>
                        <img
                            :src="equidamLogoPath"
                            class="biz-valuation-card__eq-logo"
                        >
                    </div>
                    <button
                        class="vc4a-btn vc4a-btn--secondary"
                        @click="showEstimateForm = true"
                    >
                        {{ $t('biz_valuation.empty_state.cta') }}
                        <ArrowRightIcon class="icon"/>
                    </button>
                </div>
            </div>
        </template>
    </teleport>
    <teleport to="body">
        <initial-estimate-flow
            v-if="showEstimateForm"
            :venture-name="ventureName"
            :country-form-options="countryFormOptions"
            :industry-form-options="industryFormOptions"
            :form-data-obj="formData"
            :businessValuationDataObj="formattedValuationObj"
            :show-estimate-results="showEstimateResults"
            :query-in-progress="queryInProgress"
            :has-submit-error="hasSubmitError"
            @update:create-venture="createVenture()"
            @update:submit-form="submitForm($event)"
            @update:return-to-form="showEstimateResults = false"
            @close:eq-modal="closeModal"
        />
    </teleport>
</template>

<script>
import i18n from '@/i18n';
import InitialEstimateFlow from '@/views/equidam/initial-estimate-flow.vue';
import {VC4A_VARS} from '@/shared/utlis';
import {GET_VENTURE_EQUIDAM_TENQ, CREATE_VENTURE_EQUIDAM_CID ,UPDATE_VENTURE_EQUIDAM_TENQ} from '@/graphql/equidam';
import { ArrowRightIcon } from '@heroicons/vue/16/solid';

export default {
    name: 'business-valuation-card',
    components: {
        InitialEstimateFlow,
        ArrowRightIcon
    },
    props: {
        ventureName: String,
        ventureId: String,
    },
    data() {
        return {
            showBusinessValuationCard: false,
            showEstimateForm: false,
            showEstimateResults: false,
            hasSubmitError: false,
            countryFormOptions: null,
            industryFormOptions: null,
            businessValuationData: null,
            bizValuationIllustrationPath: `${VC4A_VARS.VC4A_SITE_ASSETS_PATH}images/equidam/calculator-dynamic-color.png`,
            equidamLogoPath: `${VC4A_VARS.VC4A_SITE_ASSETS_PATH}images/equidam/eq-logo-dark.svg`,
            referralLinkToEquidam: 'https://go.vc4a.com/p1l', //https://www.equidam.com/?r=3sPVuUR4eLcOOqvweKaPX0T6rBEocazP4rNI3gAz',
            formData: null,
            queryInProgress: false,
            isCreateVentureMutationInProgress: false,
            activeNavigationTab: null,
            componentKey: 0
        }
    },
    methods: {
        getFormOptions() {
            this.queryInProgress = true;
            this.$apollo.query({
                query: GET_VENTURE_EQUIDAM_TENQ,
                variables: {
                    id: parseInt(this.ventureId),
                    idType: 'database_id',
                }
            }).then((resp) => {
                const eqData = {...resp.data.equidam}
                const ventureData = {...resp.data.venture}
                this.countryFormOptions = JSON.parse(eqData.countries);
                this.industryFormOptions = JSON.parse(eqData.industries);
                this.formData = JSON.parse(ventureData.equidam_tenq);
                this.businessValuationData = ventureData.equidam_valuation.length
                    ? JSON.parse(ventureData.equidam_valuation)
                    : null;
                this.queryInProgress = false
            }).catch((err) => {
                console.error(err);
                this.queryInProgress = false
            })
        },
        
        // BO: This was commented out temporarily because duplicate profiles are sometimes created
        createVenture() {
            // this.isCreateVentureMutationInProgress = true;
            // let variables = {
            //     input: {
            //         venture_id: parseInt(this.ventureId)
            //     }
            // };
            // this.$apollo.mutate({
            //     mutation: CREATE_VENTURE_EQUIDAM_CID,
            //     variables: variables,
            // }).then(() => {
            //     this.isCreateVentureMutationInProgress = false;
            // }).catch( (error) => {
            //     console.error(error);
            //     this.isCreateVentureMutationInProgress = false;
            // })
        },
        submitForm($event) {
            this.queryInProgress = true;
            this.showEstimateResults = true;
            
            if (this.isCreateVentureMutationInProgress) {
                // If createVenture mutation isn't resolved, wait till it completes and submit form immediately
                // Stop watching once function begins to run
                const unwatch = this.$watch(
                    () => this.isCreateVentureMutationInProgress, // The property to watch
                    (newVal) => {
                        if (!newVal) {
                            this.submitFormMutation($event);
                            unwatch(); // Stop watcher after the function has executed
                        }
                    }
                )
            } else  {
                this.submitFormMutation($event);
            }
        },
        submitFormMutation($event){
            let variables = {
                input: {
                    venture_id: parseInt(this.ventureId),
                    equidam_tenq: JSON.stringify($event)
                }
            };
            this.$apollo.mutate({
                mutation: UPDATE_VENTURE_EQUIDAM_TENQ,
                variables: variables,
            }).then( (resp) => {
                if (resp.data.updateVentureTenq && resp.data.updateVentureTenq.status) {
                    const result = resp.data.updateVentureTenq.equidam_valuation;
                    this.businessValuationData = JSON.parse(result);
                }
                this.hasSubmitError = false;
                this.queryInProgress = false
            }).catch( (error) => {
                console.error(error);
                this.hasSubmitError = true;
                this.queryInProgress = false;
            });
        },
        closeModal() {
            this.showEstimateForm = false;
            this.showEstimateResults = false;
        },
        getActiveNavigationTab() {
            const urlSegments = window.location.pathname.split('/')
            
            //If the url ends with a trailing "/", the first pop returns an empty string
            this.activeNavigationTab = urlSegments.pop() || urlSegments.pop()
        }
    },
    watch: {
        activeNavigationTab(newVal) {
            this.showBusinessValuationCard = newVal === 'fundraising';
            
            // Teleport needs to target to be readily available in the DOM tree
            // If the user isn't on the fundraising tab, teleport will not work.
            // This re-renders the entire component when the user is on the right tab
            
            if (this.showBusinessValuationCard) {
                this.componentKey++;
            }
        }
    },
    mounted() {
        this.getFormOptions();
        this.getActiveNavigationTab();
        
        // Watch the venture nav for DOM changes, get active Navigation tab after change
        const navigationTabs = document.querySelector(".venture__nav");
        const config = { attributes: true, childList: true, subtree: true };
        const navigationTabsObserver = new MutationObserver(this.getActiveNavigationTab);
        
        navigationTabsObserver.observe(navigationTabs, config);
    },
    
    computed: {
        showValuationResults() {
            if (this.businessValuationData) {
                return !!(this.businessValuationData.valuation.range.high || this.businessValuationData.valuation.range.low);
            }
        },
        
        highValuationWithCommas() {
            if (this.businessValuationData) {
                return this.businessValuationData.valuation.currency_symbol + i18n.global.n(this.businessValuationData.valuation.range.high, 'decimal')
            }
            return null;
        },
        lowValuationWithCommas() {
            if (this.businessValuationData) {
                return this.businessValuationData.valuation.currency_symbol + i18n.global.n(this.businessValuationData.valuation.range.low, 'decimal')
            }
            return null;
        },
        highValuationCompact() {
            if (this.businessValuationData) {
                return this.businessValuationData.valuation.currency_symbol + i18n.global.n(this.businessValuationData.valuation.range.high, 'short')
            }
            return null;
        },
        lowValuationCompact() {
            if (this.businessValuationData) {
                return this.businessValuationData.valuation.currency_symbol + i18n.global.n(this.businessValuationData.valuation.range.low, 'short')
            }
            return null;
        },
        formattedValuationObj() {
            return {
                lowValuationWithCommas: this.lowValuationWithCommas,
                lowValuationCompact: this.lowValuationCompact,
                highValuationWithCommas: this.highValuationWithCommas,
                highValuationCompact: this.highValuationCompact,
            }
        }
    }
}
</script>
