<template>
    <vc4a-text-input
        :label="$t('initial_estimate.form.founder_number', { ventureName: ventureName })"
        type="number"
        :min="1"
        :max="10"
        :modelValue="foundersNumber"
        @input="$emit('update:foundersNumber', Number($event.target.value))"
        @blur="v$.foundersNumber.$touch()"
        :error-text="getErrorMessage('foundersNumber')"
        class="mb-6"
    />
    <vc4a-text-input
        :label="$t('initial_estimate.form.employee_number', { ventureName: ventureName })"
        type="number"
        :min="0"
        :max="100000"
        :modelValue="employeesNumber"
        @input="$emit('update:employeesNumber', Number($event.target.value))"
        @blur="v$.employeesNumber.$touch()"
        :error-text="getErrorMessage('employeesNumber')"
        class="mb-6"
    />
    <vc4a-multiselect
        :label="$t('initial_estimate.form.country', { ventureName: ventureName })"
        class="mb-6"
        :options="countryOptions"
        track-by="alpha-2"
        option-label="name"
        :modelValue="country"
        @update:modelValue="$emit('update:country', $event);"
        :multiple="false"
        :autoClose="true"
        :searchable="true"
        @close="v$.country.$touch()"
        :error-text="getErrorMessage('country')"
    />
    <vc4a-multiselect
        :label="$t('initial_estimate.form.industry', { ventureName: ventureName })"
        class="mb-6"
        :options="industryOptions"
        track-by="code"
        option-label="name"
        group-values="childItems"
        group-label="group"
        :modelValue="industry"
        @update:modelValue="$emit('update:industry', $event)"
        :multiple="false"
        :autoClose="true"
        open-direction="bottom"
        :searchable="true"
        @close="v$.industry.$touch()"
        :error-text="getErrorMessage('industry')"
    
    />
    <vc4a-multiselect
        :label="$t('initial_estimate.form.started_year')"
        class="mb-6"
        :options="startedYearOptions"
        :modelValue="startedYear"
        @update:modelValue="$emit('update:startedYear', $event)"
        :multiple="false"
        :autoClose="true"
        :searchable="true"
        open-direction="bottom"
        @close="v$.startedYear.$touch()"
        :error-text="getErrorMessage('startedYear')"
    />
</template>

<script>
import Vc4aTextInput from "@stylesComponents/vue/vc4a-text-input.vue"
import Vc4aMultiselect from "@stylesComponents/vue/vc4a-multiselect.vue"
import { useVuelidate } from "@vuelidate/core"
import { required, minValue, maxValue, getErrorMessage } from '@stylesComponents/js/i18n-validator';

export default {
    name: "InitialEstimateStep-1",
    components: {
        Vc4aTextInput,
        Vc4aMultiselect
    },
    props: {
        foundersNumber: {
            type: Number
        },
        employeesNumber: {
            type: Number,
        },
        startedYear: {
            type: [ Number, String ]
        },
        country: {
            type: [ String, Object ]
        },
        industry: {
            type: [ String, Object ]
        },
        ventureName: {
            type: String,
        },
        countryOptions: Array,
        industryOptions: Array
    },
    setup() {
      return {
          v$: useVuelidate()
      }
    },
    emits: [ "update:foundersNumber", "update:employeesNumber", "update:startedYear", 'update:country', 'update:industry' ],
    validations() {
        return {
            foundersNumber: {
                required,
                minValue: minValue(1),
                maxValue: maxValue(10)
            },
            employeesNumber: {
                required,
                minValue: minValue(0),
                maxValue: maxValue(100000)
            },
            country: { required },
            industry: { required },
            startedYear: { required },
            countryOptions: Array,
            industryOptions: Array
        }
    },
    data() {
        return {
            startedYearCount: 50
        }
    },
    methods: {
        getErrorMessage
    },
    computed: {
        
        // Returns "y" number of years counting down from current year
        startedYearOptions() {
            let currentYear = new Date().getFullYear();
            let options = [];
            for (let i = 0; i <= this.startedYearCount; i++) {
                options.push( currentYear - i );
            }
            return options;
        }
    }
}
</script>
