export default {
  /* eslint  quotes: ["warn", "double"], indent: ["warn", 2] */
  "translation": {
    "common": {
      "success": {
        "changes": "Changes saved successfully"
      },
      "errors": {
        "basic": "Something went wrong. Please try again."
      },
      "tags": {
        "is_optional": "optional",
      },
      "ctas": {
        "cancel": "Cancel",
        "save_changes": "Save changes",
      },
      "options": {
        "yes": "Yes",
        "no": "No",
      },
    },
    "email": {
      "sender": "Sender",
      "subject": "Subject",
      "body": "Body",
      "cta": "Send email",
      "cta_group": "Send group message",
      "recipients": "Recipient: | Recipients:",
    },
    "cohorts": {
      "settings": {
        "cohort_status": {
          "active_state": {
            "title": "Active",
            "text": "This option enables cohort visibility. Cohort members will be able to view and engage with the cohort's reports. Make sure to use this feature when the cohort is ready to be shared with the group."
          },
          "inactive_state": {
            "title": "Inactive",
            "text": "This option disables cohort visibility. This ensures that the cohort's content remains private and is not visible on the cohort members. This is the default option when a cohort is just created."
          },
          "confirm_active": {
            "title": "Confirm cohort visibility",
            "body": "By confirming, you are enabling cohort visibility. Cohort members will gain access to view and engage with the cohort's reports. This action should be taken when the cohort is ready to be shared with the group.\n\nThis ensures that the cohort's content will be visible to all members on the venture profile. Are you sure you want to proceed?",
            "cta": "Enable visibility",
          },
          "confirm_inactive": {
            "title": "Confirm cohort privacy",
            "body": "By confirming, you are disabling cohort visibility. This ensures that the cohort's content remains private and is not visible to cohort members.\n\nThis means the cohort's reports will no longer be accessible to members on the venture profile. Are you sure you want to proceed with disabling visibility?",
            "cta": "Disable visibility",
          }
        },
        "messaging": {
          "edit_email": "Edit email",
          "modal_title": "Messaging",
          "reporting": {
            "title": "Reporting emails",
          },
          "reminder": {
            "title": "Enable reminders",
            "body": "Enables reminder notifications and emails going out to the members before each reporting date.",
          },
        },
        "remove_member_btn": "Remove from cohort",
      },
    },
    "venture_add": {
      "title_step_1": "Venture details",
      "title_step_2": "Venture contact and audience",
      "title_step_3": "Online presence",
      "customer_type_modal": {
        "title": "More information for type of customers"
      },
      "step_counter": "Step {{counter}} of {{total}}",
      "form_controls": {
        "previous": "Back",
        "next": "Continue",
        "submit": "Save and view venture profile",
      },
      "field_titles": { // title html attr
        "company_name": "What is the name of your company?",
        "choose_global": "Global",
        "pitch_video_url": "For example, http://vimeo.com/28175752. Your video will be embedded in your venture profile",
        "tagline": "Company Tagline",
      },
      "field_labels": {
        "address_main": "Full Address",
        "city": "City",
        "company_name": "Company name",
        "country": "Country",
        "customer_base": "Customer Base",
        "customer_type": "Type of customers",
        "focus_locations": "Countries",
        "founding_date": "Founding date",
        "phone_number": "Phone number",
        "pitch_video_url": "Pitch Video URL",
        "pitch": "Pitch",
        "sectors": "Sectors",
        "stage": "Stage",
        "street_address": "Street Address",
        "tagline": "Tagline",
        "website_url": "Website",
      },
      "field_help_text": {
        "address_auto": "Fill in the address and confirm by selecting the address from the list.",
        "address_m_text": "Can't find address? Try a nearby landmark or city or",
        "address_m_link": "Enter the address manually",
        "customer_type": "Multiple customer models can be selected",
        "customer_type_link": "View more details for the models",
        "focus_locations": "Select the countries you are currently targeting with your product or service.",
        "phone_number": "Please include any country number",
        "pitch_video_url": "This can be the URL to your Instagram, YouTube, Vimeo, DailyMotion video or Slideshare presentation.",
        "pitch": "Be as short, concise and to the point as possible. Tell us what you do and why your business has potential.",
        "sectors": "In which industry is your company most active? Select maximum 3 sectors.",
        "tagline": "Tell us in just a few words what your business is about.",
        "website_url": "If you don't have a website, please provide your business’ social media page (such as Facebook or LinkedIn).",
      },
      "field_placeholders": {
        "pitch_video_url": "Add your full YouTube or Vimeo link here. Make sure it starts with http://"
      },
      "field_customer_base": { // TODO: make this customer_base_field
        "titles": {
          "urban": "Urban based customers",
          "rural": "Rural based customers",
        },
        "descriptions": {
          "urban": "Customers are mostly in towns and cities.",
          "rural": "Customers are mostly in villages and rural areas.",
        }
      }
    },
    "validations": {
      "required": "This field is required",
      "minValue": "This field must be at least {min}",
      "maxValue": "This field must be at most {max}",
      "email": "Please enter a valid email address",
      "minLength": "This field should contain at least {min} characters",
      "maxLength": "This field should contain at most {max} characters",
      "integer": "This value should be an integer",
    },
    "initial_estimate": {
      "heading": {
        "title": "Initial estimate",
        "description": "Get started by creating an estimate of your company based on what other companies are worth.",
        "section_venture_basics": "Venture basics",
        "section_venture_stage": "Venture stage",
        "section_venture_operations": "Venture operations"
      },
      "form": {
        "founder_number": "How many founders does {ventureName} have?",
        "employee_number": "How many employees work for {ventureName}?",
        "country": "Which country is {ventureName} based?",
        "industry": "What industry does {ventureName} belong to?",
        "started_year": "When was the company started?",
        "dev_stage": "What stage of development are you facing now?",
        "ip_type": "What kind of Intellectual Property (IP) applies to the product/service?",
        "profitability": "Is {ventureName} sustainably break even?",
        "revenue_y1": "Revenue in the past year ($)",
        "revenue_y4": "Revenue projections for 3 years from now ($)",
        "currency": "Currency"
      },
      "dev_stage_options": {
        "type1_title": "Idea stage",
        "type1_desc": "Working on a business plan and testing the initial problem-solution assumptions",
        "type2_title": "Development stage",
        "type2_desc": "Building the product and establishing operations",
        "type3_title": "Startup stage",
        "type3_desc": "Active with yearly revenue up to $300,000",
        "type4_title": "Expansion stage",
        "type4_desc": "Scaling operations, with yearly revenue in excess of $300,000",
        "type5_title": "Growth stage",
        "type5_desc": "A sizable business with robust, proven, and repeatable growth",
        "type6_title": "Maturity stage",
        "type6_desc": "A profitable business with predictable growth aligned with industry"
      },
      "ip_type_options": {
        "type1_title": "Trademark and/or domain names",
        "type2_title": "Copyright",
        "type3_title": "Patent (excluding US provisional patent)",
        "type4_title": "Other",
        "type5_title": "None or not applicable",
      },
      "profitability_options": {
        "type1_title": "Yes",
        "type2_title": "No",
      },
      "controls":{
        "back": "Back",
        "continue": "Continue",
        "cta": "Estimate my valuation"
      },
      "results": {
        "title": "Initial estimate complete",
        "desc": "{ventureName} has been initially estimated to be valued between {valuationRange}",
        "cta": "Back to venture profile",
        "loading": {
          "title": "Crunching the Numbers...",
          "desc": "We're generating your initial estimate. This won't take long—just a few seconds!"
        },
        "error": {
          "title": "Oops! Something went wrong",
          "desc": "An error occurred. Please try again, or refresh the page if the problem persists.",
          "cta": "Please try again"
        }
      }
    },
    "biz_valuation": {
      "empty_state": {
        "desc": "Leverage industry-leading methodology and market data to calculate a preliminary valuation for your venture.",
        "cta": "Get an initial estimate"
      },
      "title": "Venture Valuation Tool",
      "high_bound_valuation": "High bound valuation",
      "low_bound_valuation": "Low bound valuation",
      "venture_estimate": "Your venture has been estimated to be valued between {range}. {cta}",
      "edit_estimate_cta": "Edit initial estimate",
      "goto_equidam_explainer": "Need a full valuation report? {cta} and enjoy a 10% discount.",
      "goto_equidam_cta": "Proceed with Equidam",
      "powered_by": "Powered by"
    }
  },
};
