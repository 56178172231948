<template>
    
    <!-- This component was migrated from "mentors/src/views/utility/vc4a-select.vue" -->
    <!-- Please import this instead of the original -->
    <div class="vc4a-multiselect">
        <div
            v-if="label"
            :class="size === 'lg' ? 'vc4a-multiselect__label-lg' : 'vc4a-multiselect__label'"
        >
            {{ label }}
        </div>
        <VueMultiselect
            :modelValue="modelValue"
            @update:modelValue="$emit('update:modelValue', $event)"
            :placeholder="placeholder"
            :searchable="searchable"
            :options="options"
            :multiple="multiple"
            :close-on-select="autoClose"
            :max="max"
            :track-by="trackBy"
            :label="optionLabel"
            selectLabel=""
            selectGroupLabel=""
            selectedLabel="✓"
            deselectLabel="✕"
            deselectGroupLabel="✕"
            :openDirection="openDirection ? openDirection : null"
            :group-values="groupValues ? groupValues : null"
            :group-label="groupLabel"
            :group-select="groupSelect"
            :class="{ 'has-error': errorText, 'multiselect--group': groupSelect }"
            @close="$emit('close')"
            :disabled="disabled"
        />
        <div class="vc4a-multiselect__error">
            {{ errorText }}
        </div>
    </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';


export default {
    name: 'Vc4aSelect',
    props: {
        options: {
            type: Array,
            default: () => []
        },
        placeholder: String,
        size: {
            type: String,
            default: 'md',
        },
        max: Number,
        multiple: {
            type: Boolean,
            default: true
        },
        autoClose: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        optionLabel: {
            type: String,
            default: ''
        },
        trackBy: {
          type: String,
        },
        searchable: {
            type: Boolean,
        },
        modelValue: {
            type: [Array, Object, String, Number],
        },
        summarised: {
            type: Boolean,
            default: false
        },
        summarisedStartValue: {
            type: Number,
            default: 3
        },
        errorText: {
            type: String
        },
        groupValues: {
            type: String,
            default: ''
        },
        groupLabel: {
            type: String,
            default: 'name'
        },
        groupSelect: {
            type: Boolean,
            default: false
        },
        openDirection: {
            type: String,
            default: 'bottom'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    components: {
        VueMultiselect
    },
    emits:['update:modelValue', 'close', 'onInput'],
    data() {
        return {
            globalId: 'global-' + ( Math.floor(Math.random() * 10000) + 1 )
        }
    },
    mounted() {
        this.findOption();
    },
    watch: {
        options() {
            this.findOption();
        }
    },
    methods: {
        findOption() {
            // Find option from string
            if ( this.options.length && this.trackBy && typeof (this.options[0]) === 'object' ) {
                let foundElement = this.options.find( element => element[this.trackBy] === this.modelValue )
                if (foundElement) this.$emit( 'update:modelValue', foundElement )
            }
        }
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css">

</style>
